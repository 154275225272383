import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FaYelp, FaMapMarkerAlt } from "react-icons/fa"
import logo from "../images/logo-wcc.svg"

const Footer = () => {
  const {
    site: {
      siteMetadata: { phone, email, yelp },
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
          email
          phone
          yelp
        }
      }
    }
  `)

  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="xl:col-span-1">
            <img className="h-10" src={logo} alt="Company name" />
            <p className="mt-8 text-gray-500 text-base leading-6">
              At White Center Chiropractic it is our goal to provide
              professional health care with a personal touch.
            </p>
            <div className="mt-8 flex">
              <a href={yelp} className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">Yelp</span>
                <FaYelp />
              </a>
              <a
                href="https://goo.gl/maps/G7DyTPpyEg1Y6Dia8"
                className="ml-6 text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">Google Maps</span>
                <FaMapMarkerAlt />
              </a>
            </div>
          </div>
          <div className="mt-12 grid grid-cols-1 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                  Contact
                </h4>
                <ul className="mt-4 text-sm leading-6 text-gray-500">
                  <li className="mb-2">
                    <address className="not-italic">
                      White Center Chiropractic
                      <br />
                      10030 15th Ave SW
                      <br />
                      Seattle, WA 98146
                    </address>
                    <a
                      href="https://www.google.com/maps/dir//White+Center+Chiropractic,+15th+Avenue+Southwest,+Seattle,+WA/@47.5354694,-122.4055487,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x549043ddf29b4005:0xc2ab64e3c0bcece9!2m2!1d-122.3535!2d47.5129389"
                      className="underline"
                    >
                      Get directions
                    </a>
                  </li>
                  <li className="mb-1">
                    <a href={`tel:${phone}`} className="underline">
                      {phone}
                    </a>
                  </li>
                  <li className="mb-1">
                    <a href="/contact#contact-form" className="underline">
                      Contact us
                    </a>
                  </li>
                </ul>
              </div>

              <div className="mt-12 md:mt-0">
                <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                  Walk-In Hours
                </h4>
                <ul className="mt-4 mb-4 text-sm leading-6 text-gray-500">
                  <li>
                    <strong className="font-medium">Mon and Wed:</strong> 10
                    a.m. - 1:30 p.m. & 3 - 6 p.m.
                  </li>
                  <li>
                    <strong className="font-medium">Tues and Thurs:</strong> 3 -
                    6 p.m.
                  </li>
                  <li>
                    <strong className="font-medium">Fri:</strong> 10 a.m. - 2
                    p.m.
                  </li>
                </ul>
                <p className="text-sm text-gray-500">
                  Please call for appointments outside of the walk-in hours.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <p className="text-sm leading-6 text-gray-400 xl:text-center">
            &copy; {new Date().getFullYear()} White Center Chiropractic. All
            rights reserved.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
