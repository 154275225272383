import React, { useState } from "react"
import { Link } from "gatsby"
import logo from "../images/logo-wcc.svg"

const NavItem = ({ children, ...props }) => (
  <Link
    className="py-2 px-3 w-full sm:w-auto text-center sm:inline-block text-gray-500 hover:text-blue-500"
    activeClassName="text-blue-500"
    {...props}
  >
    {children}
  </Link>
)

const Nav = () => {
  const [showNav, toggleNav] = useState(false)

  const handleToggleNav = e => {
    toggleNav(!showNav)
  }
  return (
    <nav className="flex justify-between flex-wrap items-center px-4 py-6 border-b border-gray-200">
      <div className="w-40 mr-6">
        <Link to="/">
          <img src={logo} alt="White Center Chiropractic Logo" />
        </Link>
      </div>
      <div className="block sm:hidden">
        <button
          className="flex items-center px-3 py-2 border rounded border-teal-400"
          onClick={handleToggleNav}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        className={`flex flex-auto sm:flex-none flex-wrap items-stretch font-medium mt-4 sm:mt-0 ${
          showNav ? "block" : "hidden"
        } sm:block`}
      >
        <NavItem to="/about">About</NavItem>
        <NavItem to="/services">Services</NavItem>
        <a
          href="/contact"
          className="py-2 px-3 w-full sm:w-auto text-center sm:inline-block text-gray-500 hover:text-blue-500"
        >
          Contact
        </a>
        <Link
          to="/new-patient"
          className="sm:ml-3 text-center w-full sm:w-auto text-sm px-4 py-2 leading-none border rounded text-blue-500 border-blue-500 hover:text-white hover:bg-blue-500 lg:mt-0"
        >
          New Patient
        </Link>
      </div>
    </nav>
  )
}

export default Nav
